<template>
<div>

</div>
</template>
<script>
    export default {
        components: {},
        data() {
            return {

            }
        },
        mounted() {
        },
        beforeCreate() {
            this.$auth.redirectToShop();
        },
        methods: {


        },
    }
</script>